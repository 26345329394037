import axios from 'axios';
import authHeader from './auth-header';
import BASE_URL from '../common/BASE_URL'

const USERS_API_URL = `${BASE_URL}/users`;

class UserService {
  getPublicContent() {
    // check if valid user
    // Go to: https://server.thebookclub.vishwarajanand.com/users?accessToken=abcd_token
    if ('x-access-token' in authHeader()) {
      let token = authHeader()['x-access-token'];
      return axios.get(`${USERS_API_URL}?accessToken=${token}`);
    }
    else {
      window.location.href = "/login";
    }
  }

  getAllUsers() {
    return axios.get(`${USERS_API_URL}`);
  }

  async genCurrentUserId() {
    let user = await this.getPublicContent();
    return user.data[0].id;
  }

  getUsernameFromUserid(userid) {
    let user_id_filter = userid ? `id=${userid}` : "";
    return axios.get(`${USERS_API_URL}?${user_id_filter}`);
  }

  getUserBoard() {
    return this.getPublicContent();
  }

  getModeratorBoard() {
    return this.getPublicContent();
  }

  getAdminBoard() {
    return this.getPublicContent();
  }

  patchUser(user) {
    if (!user.id) {
      return -1;
    }
    let updated_user = { id: user.id };
    if (user.new_username) {
      updated_user.username = user.new_username;
    }
    if (user.new_email && user.email !== user.new_email) {
      updated_user.email = user.new_email;
    }
    if (user.username && user.username !== user.new_username) {
      updated_user.username = user.new_username;
    }
    if (user.new_roles && user.new_roles.length >= 1) {
      updated_user.roles = user.new_roles;
    }
    if (user.password &&
      user.new_password &&
      user.confirm_password &&
      user.new_password === user.confirm_password &&
      user.password !== user.new_password
    ) {
      updated_user.password = user.new_password;
    }
    if (!(user.existing_password && user.password && user.existing_password === user.password)) {
      alert("password mismatch");
      return -1;
    }

    let update_api = axios
      .patch(`${USERS_API_URL}/${user.id}`, { ...updated_user });
    return update_api;
  }
}

export default new UserService();
