import axios from 'axios';
import authHeader from './auth-header';
import BASE_URL from '../common/BASE_URL'

const BOOKS_API_URL = `${BASE_URL}/books/`;
const REVIEWS_API_URL = `${BASE_URL}/book_reviews/`;

class BookService {
  async getTopNBooks(N = 50) {
    const [books] = await Promise.all([
      // axios.get(`${USERS_API_URL}?accessToken=${token}`),
      axios.get(`${BOOKS_API_URL}?limit=${N}`)]);
    // if (!user.data) {
    //   return [];
    //   // window.location.href = "/login";
    // }
    return books.data;
  }

  async getTopNFilteredBooks(searchString, N = 50) {
    searchString = searchString.replace(/=/g, ":");
    if (searchString.includes(":")) {
      searchString = `filter=${searchString}`;
    } else {
      searchString = `title=${searchString}`;
    }
    const [books] = await Promise.all([
      // axios.get(`${USERS_API_URL}?accessToken=${token}`),
      axios.get(`${BOOKS_API_URL}?${searchString}&limit=${N}`)]);
    // if (!user.data) {
    //   return [];
    //   // window.location.href = "/login";
    // }

    return books.data;
  }

  getBookReviews(book_id = 0) {
    if (!book_id || book_id < 1) {
      return (() => { return []; });
    }
    let book_id_filter = book_id ? `book_id=${book_id}` : "";
    return axios.get(`${REVIEWS_API_URL}?${book_id_filter}`);
  }

  // assumes book is in correct format
  async addBook(book) {
    if (!('x-access-token' in authHeader())) {
      window.location.href = "/login";
      return;
    }

    let added_book = await axios.post(BOOKS_API_URL, book);
    return added_book.data;
  }

  // assumes review is in correct format
  async addBookReview(review) {
    if (!('x-access-token' in authHeader())) {
      window.location.href = "/login";
      return;
    }

    let added_review = await axios.post(REVIEWS_API_URL, review);
    return added_review.data;
  }

  async patchBook(book) {
    if (!book.id) {
      return -1;
    }
    let updated_book = { id: book.id };
    if (book.new_like_count) {
      updated_book.like_count = book.new_like_count;
    }
    updated_book.is_moderated = book.new_is_moderated ?? false;

    let update_api = axios
      .patch(`${BOOKS_API_URL}/${book.id}`, { ...updated_book });
    return update_api;
  }

  async patchBookReview(review) {
    if (!review.id) {
      return -1;
    }
    let updated_review = { id: review.id };
    if (review.new_like_count) {
      updated_review.like_count = review.new_like_count;
    }
    if (review.new_is_moderated) {
      updated_review.is_moderated = review.new_is_moderated;
    }

    let update_api = axios
      .patch(`${REVIEWS_API_URL}/${review.id}`, { ...updated_review });
    return update_api;
  }
}

export default new BookService();
