import React, { Component } from "react";

import BookService from "../services/book.service";
import UserService from "../services/user.service";
import AddBookForm from "./add-book-form.component";
import ShowBook from "./show-book.component";

export default class BookReviews extends Component {
  constructor(props) {

    super(props);
    this.updateSearchResults = this.updateSearchResults.bind(this);
    this.addBook = this.addBook.bind(this);

    this.state = {
      books: [],
      searchString: this.props.searchString ?? "",
    };
    // console.log("BR search string " + this.state.searchString);
  }

  async addBook(e) {
    e.preventDefault();

    this.form.validateAll();

    let book = {
      title: this.state.addTitle,
      author_name: this.state.addAuthor,
      cover_url: this.state.addUrl,
      added_by_userid: 3,
      added_timestamp: parseInt(Date.now() / 1000), // honoring the user time and not the server time
      like_count: 0
    };
    let added_book = await BookService.addBook(book);
    console.log("BR :" + JSON.stringify(added_book));
  }

  async updateSearchResults() {
    console.log(`!!! BR update search results : ${this.props.searchString}`);

    let books = [];
    if (this.props.searchString) {
      books = await BookService.getTopNFilteredBooks(this.props.searchString);
    } else {
      books = await BookService.getTopNBooks();
    }

    let urls = [];
    for (let i = 0; i < books.length; i++) {
      let book = books[i];
      let review_url = BookService.getBookReviews(book.id ?? -1).then(
        function (resp) {
          books[i].reviews = resp.data;
        }
      );
      urls.push(review_url);
    }
    await Promise.all(urls);

    // fetch all usernames
    urls = [];
    for (let i = 0; i < books.length; i++) {
      let book = books[i];
      let user_url = UserService.getUsernameFromUserid(book.userid).then(
          function (resp) {
          if (resp.data && resp.data.length === 1 && resp.data[0].username) {
              books[i].username = resp.data[0].username;
            }
          }
        );
      urls.push(user_url);
      if (!book.reviews) {
        continue;
      }
      for (let j = 0; j < book.reviews.length; j++) {
        let review = book.reviews[j];
        let user_url = UserService.getUsernameFromUserid(review.userid).then(
            function (resp) {
            if (resp.data && resp.data.length === 1 && resp.data[0].username) {
                books[i].reviews[j].username = resp.data[0].username;
              }
            }
          );
        urls.push(user_url);
      }
    }
    await Promise.all(urls);

    this.setState({
      books: (books)
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.searchString !== prevProps.searchString) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
    {
      this.setState({
        effectiveSearchString: this.state.searchString
      });
      await this.updateSearchResults();
    }
    // console.log(`BR componentDidUpdate search results : ${this.props.searchString}`);
  }

  async componentDidMount() {
    await this.updateSearchResults();
  }

  render() {
    return (
      <div className="container app">
        <header className="jumbotron">
          <h4>
            <div>
              <AddBookForm />
            </div>
          </h4>
        </header>
        <br />
        <div>
          <div>
            {this.state.books.map((book, index) => {
              if (this.props.isModeratorViewer || !book.is_moderated) {
                return (<div key={book.id}>
                  <ShowBook book={book} isModeratorViewer={this.props.isModeratorViewer} />
                  <br />
                </div>);
              }
              return '';
            })}
          </div>
          <br />
        </div>
      </div>
    );
  }
}
