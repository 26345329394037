import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Button from "react-validation/build/button";

import AuthService from "./services/auth.service";

import { Link } from 'react-router-dom';
import BookReviews from "./components/book-reviews.component";

import EventBus from "./common/EventBus";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateSearchResults = this.updateSearchResults.bind(this);
    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
      searchString: "",
      effectiveSearchString: "",
      isModeratorViewer: false
    };
  }

  handleChange(e) {
    // console.log(`handle change: (${e.target.name}) to (${e.target.value})`);
    this.setState(
      {
        [e.target.name]:
          e.target.value
      });
  }

  updateSearchResults(e) {
    this.setState({
      effectiveSearchString: this.state.searchString
    });
    // console.log("effectiveSearchString " + this.state.effectiveSearchString);
    e.preventDefault();
  }

  async componentDidMount() {
    const user = await AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: false, //user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles && user.roles.includes("ROLE_ADMIN"),
        isModeratorViewer: user.roles && user.roles.includes("ROLE_MODERATOR")
      });
    }

    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
    // window.location.href = "/login";
  }

  render() {
    const { currentUser, showModeratorBoard, showAdminBoard } = this.state;

    return (
      <div>
        <nav className="navbar navbar-expand navbar-dark bg-dark">
          <Link to={"/"} className="navbar-brand">
            &nbsp; TBC
          </Link>
          <div className="navbar-nav mr-auto">
            {/* <li className="nav-item">
              <Link to={"/home"} className="nav-link">
                Home
              </Link>
            </li> */}

            {showModeratorBoard && (
              <li className="nav-item">
                <Link to={"/mod"} className="nav-link">
                  Moderator Board
                </Link>
              </li>
            )}

            {showAdminBoard && (
              <li className="nav-item">
                <Link to={"/admin"} className="nav-link">
                  Admin Board
                </Link>
              </li>
            )}

            {/* {currentUser && !showModeratorBoard && !showAdminBoard && (
              <li className="nav-item">
                <Link to={"/user"} className="nav-link">
                  User
                </Link>
              </li>
            )} */}
          </div>

          <div className="input-group">
            <Form className="d-flex"
              onSubmit={this.updateSearchResults}
            >
              <Input
                type="text"
                name="searchString"
                className="form-control rounded"
                value={this.state.searchString}
                onChange={this.handleChange}
                placeholder="type to search books.."
                aria-label="Search"
                aria-describedby="search-addon"
              />
              <Button variant="outline-success" className="btn btn-outline-primary">Search</Button>
            </Form>
          </div>

          {currentUser ? (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/profile"} className="nav-link">
                  {currentUser.username}
                </Link>
              </li>
              <li className="nav-item">
                <a href="/login" className="nav-link" onClick={this.logOut}>
                  LogOut
                </a>
              </li>
            </div>
          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  Login
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/register"} className="nav-link">
                  Sign Up
                </Link>
              </li>
            </div>
          )}
          &nbsp;
        </nav>
        <div className="container mt-1" style={{ paddingTop: "10px", backgroundImage: 'url(https://wallpaperaccess.com/full/2785791.jpg)', maxWidth: '100%' }} >
          &nbsp;
          <BookReviews
            searchString={this.state.effectiveSearchString}
            key={this.state.isModeratorViewer}
            isModeratorViewer={this.state.isModeratorViewer} />
        </div>
      </div>
    );
  }
}

export default App;
