import React, { Component } from "react";

import BookService from "../services/book.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Button from "react-validation/build/button";
import UserService from "../services/user.service";
import AuthService from "../services/auth.service";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default class AddReviewForm extends Component {
  constructor(props) {

    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.addBookReview = this.addBookReview.bind(this);

    this.state = {
      addReviewText: "",
      showForm: false
    };
  }

  async addBookReview(e) {
    e.preventDefault();

    this.form.validateAll();

    let review = {
      userid: await UserService.genCurrentUserId(),
      added_timestamp: parseInt(Date.now() / 1000), // honoring the user time and not the server time
      book_id: this.props.book_id,
      review_comment: this.state.addReviewText,
      like_count: 0
    };
    let added_review = await BookService.addBookReview(review);
    console.log("New review added :" + JSON.stringify(added_review));
    window.location.href = "/";
  }

  handleChange(e) {
    // console.log(`BR handle change: (${e.target.name}) to (${e.target.value})`);
    this.setState(
      {
        [e.target.name]:
          e.target.value
      });
  }

  async componentDidMount() {
    let user = await AuthService.getCurrentUser();
    this.setState(
      {
        showForm: (user ? true : false)
      });
  }

  render() {
    return (
      <div
        style={{ display: (this.state.showForm ? "block" : "none") }}>
        <Form className="d-flex"
          onSubmit={this.addBookReview}
          ref={c => {
            this.form = c;
          }}>
          <Input
            type="text"
            name="addReviewText"
            className="form-control rounded"
            value={this.state.addReviewText}
            onChange={this.handleChange}
            placeholder="Add review or highlights"
            validations={[required]}
          />
          &nbsp;
          &nbsp;
          <Button variant="outline-success" className="btn btn-outline-primary">Add Review</Button>
        </Form>
      </div>
    );
  }
}
