import React, { Component } from "react";

import Card from 'react-bootstrap/Card';
import AddReviewForm from "./add-review-form.component";
import Markdown from 'marked-react';
import BookService from "../services/book.service";

export default class ShowBook extends Component {
  constructor(props) {
    super(props);
    this.truncate = this.truncate.bind(this);
    this.timeSince = this.timeSince.bind(this);
    this.likeBook = this.likeBook.bind(this);
    this.likeReview = this.likeReview.bind(this);
    this.moderateBook = this.moderateBook.bind(this);

    this.state = {
      book: this.props.book
    };
    this.state.book.is_moderated = this.state.book.is_moderated ?? false;
  }

  likeBook(e, book_id, cur_like_count) {
    console.log(`like Book id ${book_id} with ${cur_like_count} likes`);
    let newState = this.state.book;
    if (newState.id === book_id) {
      newState.like_count++;
    }
    this.setState({
      book: (newState)
    });

    //update the DB
    let updated_book = {
      id: book_id,
      new_like_count: cur_like_count + 1
    };
    BookService.patchBook(updated_book).then(
      response => {
        this.setState({
          message: response.data
        });
        // alert("liked the book");
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        alert(`Book could not be liked: ${resMessage}`);
      }
    );
  }

  likeReview(e, review_id, cur_review_count) {
    console.log(`like Review id ${review_id} with ${cur_review_count} likes`);
    let newState = this.state.book;
    for (let i = 0; i < newState.reviews.length; i++) {
      let review = newState.reviews[i];
      if (review.id === review_id) {
        newState.reviews[i].like_count++;
      }
    }
    this.setState({
      book: (newState)
    });

    //update the DB
    let updated_review = {
      id: review_id,
      new_like_count: cur_review_count + 1
    };
    BookService.patchBookReview(updated_review).then(
      response => {
        this.setState({
          message: response.data
        });
        // alert("liked the review");
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        alert(`Review could not be liked: ${resMessage}`);
      }
    );
  }

  moderateBook(e, book_id, moderation_flag) {
    console.log(`Moderate this book: ${book_id}`);

    //update the DB
    let updated_book = {
      id: book_id,
      new_is_moderated: moderation_flag ?? true
    };
    BookService.patchBook(updated_book).then(
      response => {
        this.setState({
          message: response.data
        });
        // alert("book added for moderation");
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        alert(`Book could not be moderated: ${resMessage}`);
      }
    );
    let newState = this.state.book;
    newState.is_moderated = moderation_flag;
    this.setState({
      book: (newState)
    });
  }

  truncate(str) {
    return str.length > 1000 ? str.substring(0, 7) + "..." : str;
  }

  timeSince(date) {
    var seconds = Math.floor(parseInt(Date.now() / 1000) - date);
    var interval = seconds / 31536000; // seconds in an year
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000; // seconds in a month
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days"; // seconds in a day
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours"; // seconds in an hour
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes"; // seconds in a minute
    }
    return Math.floor(seconds) + " seconds";
  }

  render() {
    return (
      <Card key={this.state.book.id} border="secondary" style={{ width: 'auto' }}>
        {/* TODO: implement a preview image generator for the URL */}
        <Card.Img variant="top" src="logo192.png" style={{ height: "5rem", width: "5rem" }} />
        <Card.Body>
          <Card.Title>
            {this.truncate(this.state.book.title)}
            &nbsp;-&nbsp;
            <i>{
              this.state.book.author_name ? `${this.state.book.author_name}` : 'anonymous'}
            </i>
          </Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            @{this.state.book.username ? this.state.book.username : this.state.book.userid} added {this.timeSince(this.state.book.added_timestamp)} ago
            &nbsp;
            <Card.Link border="info" href={this.state.book.cover_url}>External link</Card.Link>
            &nbsp;
            with
            &nbsp;
            <span className="btn btn-outline-danger"
              like_count={this.state.book.like_count}
            onClick={
              (e) => { this.likeBook(e, this.state.book.id, this.state.book.like_count) }
            }>
            {this.state.book.like_count} &hearts;
            </span>
            &nbsp;
            &nbsp;
            <span variant="btn btn-outline-danger"
              style={{
                fontSize: '35px',
                border: '1px solid black',
                borderRadius: 5,
                color: this.state.book.is_moderated ? 'red' : 'green',
                display: this.props.isModeratorViewer ? 'auto' : 'none'
              }}
              onClick={
                (e) => { this.moderateBook(e, this.state.book.id, !(this.state.book.is_moderated)) }
              }
            >
              &nbsp;&#9888;&nbsp;
            </span>
            &nbsp;
          </Card.Subtitle>

          {this.state.book.reviews && this.state.book.reviews.map((review, index) => {
            return (
              <Card key={review.id} border="light" style={{ width: 'auto' }}>
                <Card.Body>
                  <Card.Subtitle className="mb-2 text-muted">
                    @{review.username ? review.username : review.userid} commented {this.timeSince(review.added_timestamp)} ago
                    with
                    &nbsp;
                  <p className="btn btn-outline-danger"
                      like_count={review.like_count}
                    onClick={
                      (e) => { this.likeReview(e, review.id, review.like_count) }
                    }>
                    {review.like_count} &hearts;
                  </p>
                  </Card.Subtitle>
                  <Markdown>{review.review_comment}</Markdown>
                </Card.Body>
              </Card>);
          })}
          <AddReviewForm book_id={this.state.book.id} />
        </Card.Body>
      </Card >
    );
  }
}
