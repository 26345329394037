import React, { Component } from "react";

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';


export default class BoardAdmin extends Component {
  constructor(props) {
    super(props);
    this.patchAccessLevel = this.patchAccessLevel.bind(this);

    this.state = {
      content: "",
      users: []
    };
  }

  ACCESS_LEVEL = { moderator: 'ROLE_MODERATOR', admin: 'ROLE_ADMIN', user: 'ROLE_USER' }

  check_access_level(user, access_level) {
    return user && user.roles && user.roles.includes(access_level);
  }

  patchAccessLevel(e, user_id, is_admin_now, is_moderator_now) {
    let new_user = { id: user_id, new_roles: [this.ACCESS_LEVEL.user] }
    let new_roles = [this.ACCESS_LEVEL.user];
    let index = 0;
    for (index in this.state.users) {
      let user = this.state.users[index];
      if (user.id === user_id) {
        // new_roles = user.roles;
        // TODO: DO NOT REMOVE, added for compliance reasons because user.service needs refactoring
        new_user.password = user.password;
        new_user.existing_password = user.password;
        break;
      }
    }
    if (is_admin_now) {
      new_roles.push(this.ACCESS_LEVEL.admin);
    }
    if (is_moderator_now) {
      new_roles.push(this.ACCESS_LEVEL.moderator);
    }
    console.log(`${user_id} updated with new roles: ${JSON.stringify(new_roles)}`);
    new_user.new_roles = new_roles;

    UserService.patchUser(new_user).then(
      response => {
        // update state
        let new_users = this.state.users;
        new_users[index].roles = new_roles;
        this.setState({
          message: response.data.username,
          users: new_users
        });
        // alert("User details have been updated");

      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        alert(`User could not be updated: ${resMessage}`);
      }
    );
  }

  async componentDidMount() {
    UserService.getAdminBoard().then(
      response => {
        this.setState({
          content: JSON.stringify(response.data[0])
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
    UserService.getAllUsers().then(response => {
      this.setState({
        users: response.data
      });
    });
  }

  render() {
    return (
      <div className="container">
        <header className="jumbotron">
          <h3>Edit access for following users</h3>
        </header>
        <div>
          {this.state.users.map((user, index) => {
            return (<Card key={user.id} border="secondary" style={{ width: 'auto' }}>
              <Card.Body>
                <Card.Subtitle className="mb-2 text-muted">
                  {user.username} &lt;&gt; {user.email} <br /><br />
                </Card.Subtitle>

                <span variant="btn btn-outline-danger"
                  style={{
                    fontSize: '20px',
                    border: '1px solid black',
                    borderRadius: 5,
                    color: this.check_access_level(user, this.ACCESS_LEVEL.admin) ? 'green' : 'red'
                  }}
                  onClick={
                    (e) => {
                      this.patchAccessLevel(e,
                        user.id,
                        !(this.check_access_level(user, this.ACCESS_LEVEL.admin)),
                        (this.check_access_level(user, this.ACCESS_LEVEL.moderator)))
                    }
                  }
                >
                  &nbsp;&#9874; Admin &nbsp;
                </span>
                &nbsp;

                <span variant="btn btn-outline-danger"
                  style={{
                    fontSize: '20px',
                    border: '1px solid black',
                    borderRadius: 5,
                    color: this.check_access_level(user, this.ACCESS_LEVEL.moderator) ? 'green' : 'red'
                  }}
                  onClick={
                    (e) => {
                      this.patchAccessLevel(e,
                        user.id,
                        (this.check_access_level(user, this.ACCESS_LEVEL.admin)),
                        !(this.check_access_level(user, this.ACCESS_LEVEL.moderator)))
                    }
                  }
                >
                  &nbsp;&#9901; Moderator&nbsp;
                </span>
              </Card.Body>
            </Card >
            );
          })}
        </div>

        &nbsp;
        <Link to={"/"} className="btn btn-primary btn-block">
          {this.state.loading && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          <span>Back</span>
        </Link>
      </div>
    );
  }
}
