import React, { Component } from "react";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import { BrowserRouter as Routes, Route } from 'react-router-dom';
import Home from "./home.component";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { isEmail } from "validator";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = value => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vusername = value => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vpassword = value => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeOldPassword = this.onChangeOldPassword.bind(this);
    this.onChangeNewPassword = this.onChangeNewPassword.bind(this);
    this.onChangeConfirmNewPassword = this.onChangeConfirmNewPassword.bind(this);
    this.state = {
      redirect: null,
      userReady: false,
      currentUser: { username: "" }
    };
  }

  handleUserChange(e) {
    e.preventDefault();
    this.form.validateAll();
    let updated_user = {};
    updated_user.id = this.state.currentUser.id;
    updated_user.username = this.state.currentUser.username;
    updated_user.new_username = (this.state.new_username &&
      !(this.state.new_username.length < 3 ||
        this.state.new_username.length > 20)) ?
      this.state.new_username :
      this.state.currentUser.username;

    updated_user.email = this.state.currentUser.email;
    updated_user.new_email = (this.state.new_email) ?
      this.state.new_email :
      this.state.currentUser.email;
    if (this.state.currentUser.password !== this.state.old_password) {
      alert("Old password is incorrect");
      return -1;
    }
    updated_user.password = this.state.currentUser.password;
    updated_user.existing_password = this.state.old_password;
    updated_user.new_password = (this.state.new_password &&
      !(this.state.new_password.length < 6 ||
        this.state.new_password.length > 20)) ?
      this.state.new_password :
      '';
    updated_user.confirm_password = (this.state.confirm_password &&
      !(this.state.confirm_password.length < 6 ||
        this.state.confirm_password.length > 20)) ?
      this.state.confirm_password :
      '';

    UserService.patchUser(updated_user).then(
      response => {
        this.setState({
          message: response.data.username
        });
        alert("User details have been updated");
        window.location.href = "/";
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        alert(`User could not be updated: ${resMessage}`);
      }
    );
  }

  onChangeUsername(e) {
    this.setState({
      new_username: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      new_email: e.target.value
    });
  }

  onChangeOldPassword(e) {
    this.setState({
      old_password: e.target.value
    });
  }
  onChangeNewPassword(e) {
    this.setState({
      new_password: e.target.value
    });
  }
  onChangeConfirmNewPassword(e) {
    this.setState({
      confirm_password: e.target.value
    });
  }

  async componentDidMount() {
    const currentUser = await AuthService.getCurrentUser();

    if (!currentUser) this.setState({ redirect: "/home" });
    this.setState({ currentUser: currentUser, userReady: true })
  }

  render() {
    if (this.state.redirect) {
      return <Routes>
        <Route exact path={["/", "/home"]} component={Home} />
      </Routes>
    }

    const { currentUser } = this.state;

    return (
      <div className="container">
        <br />
        <Form
          onSubmit={this.handleUserChange}
          ref={c => {
            this.form = c;
          }}
        >
          {(this.state.userReady) ?
            <div>
              <header className="jumbotron">
                <h3>
                  <strong>Edit your details: </strong> {currentUser.username}
                </h3>
              </header>

              <div className="form-group">
                <label htmlFor="username">Username</label>
                <Input
                  type="text"
                  className="form-control"
                  name="username"
                  value={this.state.username}
                  onChange={this.onChangeUsername}
                  validations={[required, vusername]}
                />
              </div>
              {/* <p>
              <strong>Token:</strong>{" "}
              {currentUser.accessToken.substring(0, 20)} ...{" "}
              {currentUser.accessToken.substr(currentUser.accessToken.length - 20)}
            </p> */}
              {/* <p>
              <strong>Id:</strong>{" "}
              {currentUser.id}
            </p> */}
              {/* <p>
              <strong>Email:</strong>{" "}
              {currentUser.email}
            </p> */}
              <br />

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Input
                  type="email"
                  className="form-control"
                  name="email"
                  value={currentUser.email}
                  onChange={this.onChangeEmail}
                  validations={[required, email]}
                />
              </div>
              <br />
              <div>
                <strong>Old Password:</strong>{" "}
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChangeOldPassword}
                  validations={[required, vpassword]}
                />
              </div>
              <br />

              <div>
                <strong>New Password:</strong>{" "}
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChangeNewPassword}
                  validations={[vpassword]}
                />
              </div>
              <br />

              <div>
                <strong>Confirm Password:</strong>{" "}
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChangeConfirmNewPassword}
                  validations={[vpassword]}
                />
              </div>
              {/* <strong>Authorities:</strong> */}
              {/* <ul>
              {currentUser.roles &&
                currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
            </ul> */}
              <br />

              <div className="form-group">
                <button className="btn btn-primary btn-block">Save</button>
                &nbsp;
                &nbsp;
                <a href="/" className="btn btn-danger btn-block">Back</a>
              </div>
            </div> : "Please refresh"}
        </Form>
      </div>
    );
  }
}
