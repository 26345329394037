import React, { Component } from "react";

import BookService from "../services/book.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Button from "react-validation/build/button";
import UserService from "../services/user.service";
import AuthService from "../services/auth.service";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default class AddBookForm extends Component {
  constructor(props) {

    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.addBook = this.addBook.bind(this);

    this.state = {
      addTitle: "",
      addAuthor: "",
      addUrl: "",
      showForm: false
    };
  }

  async addBook(e) {
    e.preventDefault();

    this.form.validateAll();

    let book = {
      title: this.state.addTitle,
      author_name: this.state.addAuthor,
      cover_url: this.state.addUrl,
      userid: await UserService.genCurrentUserId(),
      added_timestamp: parseInt(Date.now() / 1000), // honoring the user time and not the server time
      like_count: 0
    };
    let added_book = await BookService.addBook(book);
    console.log("New book added :" + JSON.stringify(added_book));
    window.location.href = "/";
  }

  handleChange(e) {
    // console.log(`BR handle change: (${e.target.name}) to (${e.target.value})`);
    this.setState(
      {
        [e.target.name]:
          e.target.value
      });
  }
  async componentDidMount() {
    let user = await AuthService.getCurrentUser();
    this.setState(
      {
        showForm: (user ? true : false)
      });
  }

  render() {
    return (
      <div>
        <div style={{ display: (this.state.showForm ? "none" : "block") }}>
          <i>
            Login to add book or reviews
          </i>
        </div>
        <div
          style={{ display: (this.state.showForm ? "block" : "none") }}>
          <Form className="d-flex"
            onSubmit={this.addBook}
            ref={c => {
              this.form = c;
            }}>
            <Input
              type="text"
              name="addTitle"
              className="form-control rounded"
              value={this.state.addTitle}
              onChange={this.handleChange}
              placeholder="Add title"
              validations={[required]}
            />
            &nbsp;
            <Input
              type="text"
              name="addAuthor"
              className="form-control rounded"
              value={this.state.addAuthor}
              onChange={this.handleChange}
              placeholder="Author name (multiple? add comma)"
              validations={[required]}
            />
            &nbsp;
            <Input
              type="text"
              name="addUrl"
              className="form-control rounded"
              value={this.state.addUrl}
              onChange={this.handleChange}
              placeholder="Add Url"
              validations={[required]}
            />
            &nbsp;
            <Button variant="outline-success" className="btn btn-outline-primary">Add Book or URL</Button>
          </Form>
        </div>
      </div>
    );
  }
}
