var _eventHandlers = {}; // somewhere global
const eventBus = {
  on(event, callback, node = document, capture = false) {
    if (!(event in _eventHandlers)) {
      _eventHandlers[event] = []
    }
    let handler = (e) => callback(e.detail);
    _eventHandlers[event].push({ node: node, handler: handler, capture: capture })
    // document.addEventListener(event, (e) => callback(e.detail));
    node.addEventListener(event, handler, capture);
  },
  dispatch(event, data) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event, callback, targetNode = document) {
    if (callback) {
      document.removeEventListener(event, callback);
    }
    if (_eventHandlers[event]) {
      _eventHandlers[event]
        .filter(({ node }) => targetNode || (node === targetNode))
        .forEach(({ node, handler, capture }) => node.removeEventListener(event, handler, capture));

      // update _eventHandlers global
      _eventHandlers[event] = _eventHandlers[event].filter(
        ({ node }) => node !== targetNode,
      );
    }
  },
};

export default eventBus;
