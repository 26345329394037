import axios from "axios";
import authHeader from './auth-header';
import BASE_URL from '../common/BASE_URL'

const USERS_API_URL = `${BASE_URL}/users/`;

class AuthService {
  login(username, password) {
    let param = new URLSearchParams({
      username: username,
      password: password,
      _limit: 1
    }).toString();
    return axios
      .get(USERS_API_URL + '?' + param, {
      })
      .then(response => {
        if (response.data[0] && response.data[0].accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data[0]));
        } else {
          alert("Unable to login, check credentials");
          this.logout();
        }

        return response.data[0];
      });
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.clear();
    window.location.href = "/login";
  }

  register(username, email, password) {
    return axios.post(USERS_API_URL, {
      username: username,
      email: email,
      password: password,
      accessToken: `${username}_token`,
      roles: ["ROLE_USER"]
    });
  }

  async getCurrentUser() {
    if ('x-access-token' in authHeader()) {
      let token = authHeader()['x-access-token'];
      let api_call = await axios.get(`${USERS_API_URL}?accessToken=${token}`);
      localStorage.setItem("user", JSON.stringify(api_call.data[0]));
      return api_call.data[0];
    }
    return undefined;
  }
}

export default new AuthService();
